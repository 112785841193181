<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.company-registration.technical-conditions"></custom-page-title>

                <p>Az elektronikus cégeljárásban való részvételhez
                    elengedhetetlen az elektronikus aláírás, illetőleg időbélyegző használata.</p>

                <h2>Számítástechnikai feltételek</h2>

                <div>Az e-cégeljárás használatához a következő eszközök és
                    szolgáltatások megrendelése szükséges:
                    <ul>

                        <li>minősített elektronikus aláírás szükséges az elektronikus
                            cégeljáráshoz
                        </li>

                        <li>kártya (kártyaolvasó), vagy token
                            a minősített elektronikus aláíráshoz
                        </li>

                        <li>előfizetés időbélyeg szolgáltatásra</li>

                        <li>e-Szignó vagy Mokka aláíró program</li>
                    </ul>
                </div>
                <p></p>

                <div>A fentieken túl a következő eszközökkel kell rendelkeznie:
                    <ul>

                        <li>személyi számítógép, rendszeres biztonsági frissítésekkel ellátott
                            operációs rendszerrel
                        </li>

                        <li>szkenner</li>

                        <li>Internet kapcsolat (ADSL, kábeles internet vagy más
                            szélessávú kapcsolat ajánlott a nagy méretű szkennelt képek átvitele miatt).
                        </li>
                    </ul>
                </div>
                <p></p>

                <h2>A szkennelésre vonatkozó műszaki követelmények</h2>
                <ul>

                    <li>A szkennelt képeket kizárólag abban az esetben fogadja el a
                        cégbíróság, ha azon az eredeti papír alapú okirat szövege jól olvasható.
                    </li>

                    <li>A szkennelt kép felbontása 240-300 dpi
                        közötti lehet.
                    </li>

                    <li>A szkennelt kép fekete-fehér legyen, tehát ne színes és ne
                        szürkeskálás. (A színes aláírásokat tartalmazó oldalakat is lehet
                        fekete-fehérben szkennelni.)
                    </li>

                    <li>A szkennelt dokumentum PDF formátumú legyen.</li>

                    <li>A szkennelt dokumentum lehetőleg ne tartalmazzon üres
                        oldalakat, azaz kétoldalas szkennelésnél az üres oldalak elhagyandók.
                    </li>

                    <li>Szkennelés után ellenőrizze a szkennelt dokumentum méretét.
                        A fentiek betartása esetén a fájlméretnek oldalanként 50-100 kB körül kell
                        lennie. Ha a fájlméret oldalanként többszáz kB vagy 1 MB-nál is több, akkor
                        ellenőrizze a szkenner beállításokat és szkennelje újra a dokumentumot.
                    </li>
                </ul>
                <p></p>

                <h2>Kérelemben szereplő dokumentumokra vonatkozó követelmények</h2>

                <p>A csatolt okiratok formátuma sima szöveg (text) és PDF
                    lehet, vagy olyan ES3 kiterjesztésű elektronikus akta, amelyben az előzőleg
                    felsorolt formátumú iratok szerepelnek.</p>

                <p>A szövegszerkesztőben szerkesztett dokumentumokat PDF
                    formátumban elmentve lehet csatolni.<br>
                    Amennyiben az okiratokat papíron is aláírják (pl. a cég tulajdonosai), akkor az
                    aláírt papír okiratot kell szkenneléssel PDF formátumú elektronikus okirattá
                    alakítani.</p>

                <p>A szkennelt okiratok és az elektronikus úton keletkezett
                    okiratok vegyesen is szerepelhetnek az elektronikus kérelemben.</p>

                <h2>Az elektronikus aláírásra vonatkozó követelmények</h2>
                <ul>

                    <li>A hatályos Ctv. alapján a
                        cégeljárásban az elektronikusan küldött okiratokat minősített elektronikus
                        aláírással és időbélyeggel kell ellátni. Ez XAdES-T
                        típusú aláírást jelent, amit az e-Szignó vagy Mokka aláíró programban kell
                        beállítani. A XAdES-T-nél több információt
                        tartalmazó, a későbbi ellenőrzést megkönnyítő aláírás is alkalmazható (XAdES-X-L és XAdES-A típusú).
                    </li>

                    <li>A jogi képviselőnek elegendő a teljes bejegyzési kérelmet
                        (az elektronikus aktát) aláírni és időbélyegezni, nem
                        szükséges ezt külön-külön minden egyes szkennelt és az elektronikus aktába
                        beillesztett okiratnál megtenni
                    </li>

                    <li>Az aláíró programnak teljesíteni kell az IM Céginformációs Szolgálata
                        által közzétett követelményeket.
                    </li>
                </ul>
                <p></p>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpTechnicalConditions extends Vue {
}
</script>

<style scoped>

</style>